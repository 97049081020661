<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/funding-agency' }">
        <app-i18n code="entities.fundingAgency.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.fundingAgency.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.fundingAgency.view.title"></app-i18n>
      </h1>

      <div class="app-page-spinner" v-if="loading" v-loading="loading"></div>

      <app-funding-agency-view-toolbar v-if="record"></app-funding-agency-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        

        <app-view-item-text :label="fields.name.label" :value="presenter(record, 'name')"></app-view-item-text>

        <app-view-item-text :label="fields.management.label" :value="presenter(record, 'management')"></app-view-item-text>

        <app-view-item-text :label="fields.address.label" :value="presenter(record, 'address')"></app-view-item-text>

        <app-view-item-text :label="fields.contactPerson.label" :value="presenter(record, 'contactPerson')"></app-view-item-text>

        <app-view-item-text :label="fields.contactEmail.label" :value="presenter(record, 'contactEmail')"></app-view-item-text>

        <app-view-item-text :label="fields.phoneNumber.label" :value="presenter(record, 'phoneNumber')"></app-view-item-text>

        <app-view-item-text :label="fields.linkToWebsite.label" :value="presenter(record, 'linkToWebsite')"></app-view-item-text>

        <app-view-item-text :label="fields.createdAt.label" :value="presenter(record, 'createdAt')"></app-view-item-text>

        <app-view-item-text :label="fields.updatedAt.label" :value="presenter(record, 'updatedAt')"></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FundingAgencyViewToolbar from '@/modules/funding-agency/components/funding-agency-view-toolbar.vue';
import { FundingAgencyModel } from '@/modules/funding-agency/funding-agency-model';

const { fields } = FundingAgencyModel;

export default {
  name: 'app-funding-agency-view-page',

  props: ['id'],

  components: {
    [FundingAgencyViewToolbar.name]: FundingAgencyViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'fundingAgency/view/record',
      loading: 'fundingAgency/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'fundingAgency/view/doFind',
    }),

    presenter(record, fieldName) {
      return FundingAgencyModel.presenter(record, fieldName);
    },
  },
};
</script>
